/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px !important;
    height: 30px;
    right: 36px;
    top: 36px;
    float: right;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #ffffff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #ffffff;
}

/* Wrapper for item list */
.bm-item-list {
    color: #131211;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgb(238, 238, 238);
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: #062847;
    margin-bottom: 10px;
    font-size: 12px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
    font-family: 'Poppins';
    font-style: normal;
    line-height: 130%;
    letter-spacing: 0.116667px;
}

.bm-item:hover {
    color: #0e0d0d9d;
}

@media only screen and (max-width: 870px) {

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        width: 36px !important;
        margin-top: -10px;
        margin-bottom: 10px;
        padding-left: 1%;
        height: 30px;
        right: 36px;
        top: 36px;
        float: right;
    }
}

@media only screen and (max-width: 570px) {

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        width: 36px !important;
        margin-top: -15px;
        margin-bottom: 10px;
        padding-left: 1%;
        height: 30px;
        right: 36px;
        top: 36px;
        float: right;
    }
}