@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Zen+Antique+Soft&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px !important;
    height: 30px;
    right: 36px;
    top: 36px;
    float: right;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #ffffff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #ffffff;
}

/* Wrapper for item list */
.bm-item-list {
    color: #131211;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgb(238, 238, 238);
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: #062847;
    margin-bottom: 10px;
    font-size: 12px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
    font-family: 'Poppins';
    font-style: normal;
    line-height: 130%;
    letter-spacing: 0.116667px;
}

.bm-item:hover {
    color: #0e0d0d9d;
}

@media only screen and (max-width: 870px) {

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        width: 36px !important;
        margin-top: -10px;
        margin-bottom: 10px;
        padding-left: 1%;
        height: 30px;
        right: 36px;
        top: 36px;
        float: right;
    }
}

@media only screen and (max-width: 570px) {

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        width: 36px !important;
        margin-top: -15px;
        margin-bottom: 10px;
        padding-left: 1%;
        height: 30px;
        right: 36px;
        top: 36px;
        float: right;
    }
}
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 120px !important;
    height: 30px;
    right: 100px;
    top: 36px;
    float: right;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #ffffff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #ffffff;
}

/* Wrapper for item list */
.bm-item-list {
    color: #131211;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgb(238, 238, 238);
}

/* Individual item */
.bm-item {
    display: inline-block;
    color: #062847;
    margin-bottom: 10px;
    font-size: 12px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
    font-family: 'Poppins';
    font-style: normal;
    line-height: 130%;
    letter-spacing: 0.116667px;
}

.bm-item:hover {
    color: #0e0d0d9d;
}

@media only screen and (max-width: 870px) {

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        width: 120px !important;
        margin-top: -10px;
        margin-bottom: 10px;
        padding-left: 1%;
        height: 30px;
        right: 10px;
        top: 36px;
        float: right;
    }
}

@media only screen and (max-width: 570px) {

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        width: 120px !important;
        margin-top: -15px;
        margin-bottom: 10px;
        padding-left: 1%;
        height: 30px;
        right: 5px;
        top: 36px;
        float: right;
    }
}
* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;  
  text-decoration: none;    
  font-family: 'Poppins';
}
html, body {
  overflow-y: initial;
  background-color: #F2F2F2;
}
ul, ol, li a {
  list-style: none;
  cursor: pointer;
}
a {
  color: #07A5D4;
  cursor: pointer;
}
input {
  border:none;
  background-image:none;
  background-color:transparent;
  box-shadow: none;
  outline: 0;
  width: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
-webkit-box-shadow: 0 0 0 30px #F2F2F2 inset !important;
}

select,
button,
input,
input[type=email], 
input[type=number],
input[type=text]   {
  outline:none;
  background-color: transparent;
} 
input::-webkit-input-placeholder {
  text-transform: none;
} 
input::placeholder {
  text-transform: none;
}
input[type=email] {
  text-transform: none; 
}
button,
select {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
}

.full-height {
    height: 100%;
}

.card.full-height {
    height: calc(100% - 30px);
}

.hidden {
  display: none;
}

