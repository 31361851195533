@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Zen+Antique+Soft&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;  
  text-decoration: none;    
  font-family: 'Poppins';
}
html, body {
  overflow-y: initial;
  background-color: #F2F2F2;
}
ul, ol, li a {
  list-style: none;
  cursor: pointer;
}
a {
  color: #07A5D4;
  cursor: pointer;
}
input {
  border:none;
  background-image:none;
  background-color:transparent;
  box-shadow: none;
  outline: 0;
  width: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
-webkit-box-shadow: 0 0 0 30px #F2F2F2 inset !important;
}

select,
button,
input,
input[type=email], 
input[type=number],
input[type=text]   {
  outline:none;
  background-color: transparent;
} 
input::placeholder {
  text-transform: none;
}
input[type=email] {
  text-transform: none; 
}
button,
select {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
}

.full-height {
    height: 100%;
}

.card.full-height {
    height: calc(100% - 30px);
}

.hidden {
  display: none;
}
